<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t('routes.rights') }}
          </v-card-title>
          <v-divider />
          <div class="px-5 pb-5" style="overflow: auto">
            <table class="permissions-datatable">
              <thead>
                <tr style="margin-bottom: 2px">
                  <th></th>
                  <th v-for="([key, role], i) in Object.entries(roles)" :key="i">
                    <div class="mt-8 ml-2" style="height: 65px; width: 40px">
                      <v-tooltip bottom nudge-left="15">
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="text-start"
                            style="
                              transform: rotate(-45deg);
                              white-space: nowrap;
                              width: 120px;
                              text-overflow: ellipsis;
                              overflow: hidden;
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ role['translations'][$i18n.locale] }}
                          </div>
                        </template>
                        <span> {{ role['translations'][$i18n.locale] }} </span>
                      </v-tooltip>
                    </div>
                  </th>
                </tr>
              </thead>
              <permission-tree
                :route_dependencies="route_dependencies"
                :role_models="model.roles"
                :loading.sync="loading"
                type="vue"
                :vueToggleOnClick="vueToggleOnClick"
                :laravelToggleOnClick="laravelToggleOnClick"
              />
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import PermissionTree from './PermissionTree.vue'
export default {
  components: { PermissionTree },
  beforeMount() {
    this.$http('role_permissions').then(res => {
      this.roles = res.data.roles
      this.route_dependencies = res.data.route_dependencies
      this.createModels()
    })
  },
  data() {
    return {
      loading: false,
      roles: [],
      route_dependencies: [],
      model: {
        roles: [],
      },
    }
  },
  methods: {
    vueToggleOnClick(args) {
      this.loading = true
      this.$http({
        method: args.checked ? 'post' : 'delete',
        url: 'role_permissions/vue_permission',
        data: {
          role_id: args.role_id,
          route_name: args.route_name,
        },
      })
        .then(res => {
          this.roles = res.data
          if (args.role_id == this.$store.state.user.current_role_id) {
            this.updateNav()
          }
          this.createModels()
        })
        .finally(() => {
          this.loading = false
        })
    },

    laravelToggleOnClick(args) {
      this.loading = true
      this.$http({
        method: args.checked ? 'post' : 'delete',
        url: 'role_permissions/laravel_permission',
        data: {
          role_id: args.role_id,
          laravel_route_name: args.laravel_route_name,
          vue_route_name: args.vue_route_name,
        },
      })
        .then(res => {
          this.roles = res.data
          if (args.role_id == this.$store.state.user.current_role_id) {
            this.updateNav()
          }
          this.createModels()
        })
        .finally(() => {
          this.loading = false
        })
    },

    createModels() {
      this.model.roles = []
      for (const key in this.roles) {
        if (this.roles.hasOwnProperty(key)) {
          let role = this.roles[key]

          let role_model = {
            id: key,
            laravel_routes: {},
            vue_routes: {},
          }

          this.model.roles.push(this.createRoleModel(this.route_dependencies, role_model, role))
        }
      }
    },
    createRoleModel(route_dependencies, role_model, role) {
      route_dependencies.forEach(route => {
        if (route['vue-route']) {
          if (role['vue_permissions'].includes(route['vue-route'])) {
            role_model.vue_routes[route['vue-route']] = true
          } else {
            role_model.vue_routes[route['vue-route']] = false
          }
        }
        if (route['laravel-routes']) {
          route['laravel-routes'].forEach(laravel_route => {
            if (role['laravel_permissions'].includes(laravel_route['keyname'])) {
              role_model.laravel_routes[laravel_route['keyname']] = true
            } else {
              role_model.laravel_routes[laravel_route['keyname']] = false
            }
          })
        }
        if (route.children) {
          this.createRoleModel(route.children, role_model, role)
        }
      })
      return role_model
    },
    updateNav() {
      //in case of editing the rights of the current active user, the users nav should be updated
      this.$http('/users/self').then(res => {
        this.$store.commit('user/user', res.data)
      })
    },
    requestData() {
      let result = {}
      this.langs.forEach(lang => {
        result[lang.key] = lang.model
      })

      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.permissions-datatable {
  border-collapse: collapse !important;
}
</style>

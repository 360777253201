<template>
  <div style="display: contents">
    <template v-for="(route, i) in route_dependencies">
      <template v-if="!route['mandatory']">
        <tr :key="i">
          <td>
            <div :style="{ marginLeft: calculateIndent() }" class="mr-4">
              {{ $t(type == 'vue' ? route['title'] : route['keyname']) }}
            </div>
          </td>
          <td v-for="role_model in role_models" :key="role_model.id" class="text-center">
            <v-checkbox
              v-if="route['vue-route']"
              v-model="
                role_model[type == 'vue' ? 'vue_routes' : 'laravel_routes'][
                  route[type == 'vue' ? 'vue-route' : 'keyname']
                ]
              "
              @click="
                vueToggleOnClick({
                  role_id: role_model.id,
                  route_name: route['vue-route'],
                  checked: role_model['vue_routes'][route['vue-route']],
                })
              "
              :disabled="loading"
              :ripple="false"
              hide-details="true"
              class="ma-0 px-4"
            />
            <div
              v-else
              class="py-3"
            />
          </td>
        </tr>
        <template v-for="laravel_route in route['laravel-routes']">
          <tr v-if="laravel_route['optional'] && !laravel_route['hidden']" :key="laravel_route.keyname">
            <td>
              <div :style="{ marginLeft: calculateIndent(1) }" class="mr-4">
                {{ $t('laravel_routes.' + laravel_route['keyname']) }}
              </div>
            </td>
            <td v-for="(role_model, j) in role_models" :key="j">
              <v-checkbox
                v-model="role_model['laravel_routes'][laravel_route.keyname]"
                @click="
                  laravelToggleOnClick({
                    role_id: role_model.id,
                    laravel_route_name: laravel_route.keyname,
                    vue_route_name: route['vue-route'],
                    checked: role_model['laravel_routes'][laravel_route.keyname],
                  })
                "
                :disabled="loading"
                :ripple="false"
                :key="i"
                hide-details="true"
                class="ma-0 px-4"
              ></v-checkbox>
            </td>
          </tr>
        </template>
        <template v-if="route['children']">
          <permission-tree
            :key="'child' + i"
            :indent="indent + 1"
            :route_dependencies="route['children']"
            :role_models="role_models"
            :loading.sync="loading"
            type="vue"
            :vueToggleOnClick="vueToggleOnClick"
            :laravelToggleOnClick="laravelToggleOnClick"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PermissionTree',
  props: {
    route_dependencies: {
      type: Array,
      required: true,
    },
    //vue or laravel
    type: {
      type: String,
      required: true,
    },
    role_models: {
      type: Array,
      required: true,
    },
    indent: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    vueToggleOnClick: {
      type: Function,
      required: true,
    },
    laravelToggleOnClick: {
      type: Function,
      required: true,
    },
  },
  methods: {
    calculateIndent(extra = 0) {
      const indent_pixels_amount = 40 * (this.indent + extra)
      return indent_pixels_amount + 'px'
    },
    hasActiveChildren(children = [], role_model) {
      if (children.length == 0) {
        return false
      }

      for (let i = 0; i < children.length; i++) {
        if (role_model['vue_routes'][children[i]['vue-route']]) {
          return true
        }
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[_vm._l((_vm.route_dependencies),function(route,i){return [(!route['mandatory'])?[_c('tr',{key:i},[_c('td',[_c('div',{staticClass:"mr-4",style:({ marginLeft: _vm.calculateIndent() })},[_vm._v(" "+_vm._s(_vm.$t(_vm.type == 'vue' ? route['title'] : route['keyname']))+" ")])]),_vm._l((_vm.role_models),function(role_model){return _c('td',{key:role_model.id,staticClass:"text-center"},[(route['vue-route'])?_c('v-checkbox',{staticClass:"ma-0 px-4",attrs:{"disabled":_vm.loading,"ripple":false,"hide-details":"true"},on:{"click":function($event){return _vm.vueToggleOnClick({
                role_id: role_model.id,
                route_name: route['vue-route'],
                checked: role_model['vue_routes'][route['vue-route']],
              })}},model:{value:(
              role_model[_vm.type == 'vue' ? 'vue_routes' : 'laravel_routes'][
                route[_vm.type == 'vue' ? 'vue-route' : 'keyname']
              ]
            ),callback:function ($$v) {_vm.$set(role_model[_vm.type == 'vue' ? 'vue_routes' : 'laravel_routes'], 
                route[_vm.type == 'vue' ? 'vue-route' : 'keyname']
              , $$v)},expression:"\n              role_model[type == 'vue' ? 'vue_routes' : 'laravel_routes'][\n                route[type == 'vue' ? 'vue-route' : 'keyname']\n              ]\n            "}}):_c('div',{staticClass:"py-3"})],1)})],2),_vm._l((route['laravel-routes']),function(laravel_route){return [(laravel_route['optional'] && !laravel_route['hidden'])?_c('tr',{key:laravel_route.keyname},[_c('td',[_c('div',{staticClass:"mr-4",style:({ marginLeft: _vm.calculateIndent(1) })},[_vm._v(" "+_vm._s(_vm.$t('laravel_routes.' + laravel_route['keyname']))+" ")])]),_vm._l((_vm.role_models),function(role_model,j){return _c('td',{key:j},[_c('v-checkbox',{key:i,staticClass:"ma-0 px-4",attrs:{"disabled":_vm.loading,"ripple":false,"hide-details":"true"},on:{"click":function($event){return _vm.laravelToggleOnClick({
                  role_id: role_model.id,
                  laravel_route_name: laravel_route.keyname,
                  vue_route_name: route['vue-route'],
                  checked: role_model['laravel_routes'][laravel_route.keyname],
                })}},model:{value:(role_model['laravel_routes'][laravel_route.keyname]),callback:function ($$v) {_vm.$set(role_model['laravel_routes'], laravel_route.keyname, $$v)},expression:"role_model['laravel_routes'][laravel_route.keyname]"}})],1)})],2):_vm._e()]}),(route['children'])?[_c('permission-tree',{key:'child' + i,attrs:{"indent":_vm.indent + 1,"route_dependencies":route['children'],"role_models":_vm.role_models,"loading":_vm.loading,"type":"vue","vueToggleOnClick":_vm.vueToggleOnClick,"laravelToggleOnClick":_vm.laravelToggleOnClick},on:{"update:loading":function($event){_vm.loading=$event}}})]:_vm._e()]:_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }